// Image should probably be fetched from Hybris/Celum with a URL rather than an asset.
// Or the image should be added to the assets folder during the build
cx-page-layout.LogoutPageTemplate,
cx-page-layout.LoginPageTemplate,
cx-page-layout.PasswordResetPageTemplate {
  background-image: url('../assets-papyrus/images/blue-wallpaper.png');
  background-image: -webkit-image-set(url('../assets-papyrus/images/blue-wallpaper.webp') 1x);
}

cx-page-layout.RegistrationPageTemplate,
cx-page-layout.CheckoutPageTemplate,
cx-page-layout.OrderConfirmationPageTemplate,
cx-page-layout.PaymentProcessingPageTemplate {
  background-image: url('../assets-papyrus/images/dot-pattern-1.jpg');
  background-image: -webkit-image-set(url('../assets-papyrus/images/dot-pattern-1.webp') 1x);
  background-repeat: repeat;
  background-size: auto;
}

py-storefront.BrandLandingPageTemplate {
  main {
    background-image: url('../assets-papyrus/images/brand-landing-page-background.jpg');
    background-image: -webkit-image-set(url('../assets-papyrus/images/brand-landing-page-background.webp') 1x);
    background-position: center;
    background-size: cover;
  }
}

py-storefront.ErrorPageTemplate {
  main {
    background-image: url('../assets-papyrus/images/error-not-found-background.jpg');
    background-image: -webkit-image-set(url('../assets-papyrus/images/error-not-found-background.webp') 1x);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}
