py-footer-navigation {
  background-color: var(--py-color-primary-900) !important;

  .container {
    padding-bottom: 0 !important;
  }

  .middle-right-col,
  .right-col {
    .link-item-wrapper {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
}
