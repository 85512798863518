@mixin button-colors(
  $text-color,
  $background,
  $border,
  $background-hover,
  $border-hover,
  $background-focus: $background-hover,
  $border-focus: $border-hover,
  $background-active: $background-hover,
  $border-active: $border-hover,
  $shadow: $box-shadow,
  $shadow-hover: $box-shadow
) {
  color: $text-color;
  background-color: $background;
  border-color: $border;
  box-shadow: $shadow;

  &:hover {
    background-color: $background-hover;
    border-color: $border-hover;
    box-shadow: $shadow-hover;
    text-decoration: underline;
  }

  &:focus {
    background-color: $background-focus !important;
    border-color: $border-focus !important;
  }

  &:active {
    background-color: $background-active !important;
    border-color: $border-active !important;
    box-shadow: $shadow-hover;
  }

  &:disabled {
    background-color: var(--py-color-button-locked) !important;
    border-color: var(--py-color-button-locked) !important;
    color: var(--py-color-grey-500);
  }
}

@mixin button-outline-colors($color, $color-hover, $background-hover: $color, $border: $color, $border-hover: $color) {
  color: $color;
  border-color: $border;
  background-color: var(--py-color-white);

  &:hover {
    text-decoration: underline;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &:disabled {
    background-color: var(--py-color-button-locked) !important;
    border-color: var(--py-color-button-locked) !important;
    color: var(--py-color-grey-500);
  }

  &:not(:active):not(:focus):not(:hover):not(:disabled) {
    border-width: 1px;
    &:not(.btn-icon) {
      padding: calc(#{$padding-y} + 1px) calc(#{$padding-x} + 1px); //increasing padding to compensate for smaller border
    }
    &.btn-icon {
      padding: calc(#{$icon-padding} + 1px); //increasing padding to compensate for smaller border
    }
  }
}
