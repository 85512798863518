@mixin generate-py-variables($colors) {
  @each $color, $value in $colors {
    $red: red($value);
    $green: green($value);
    $blue: blue($value);

    .py-color-#{$color} {
      color: var(--py-color-#{$color});
    }

    @for $i from 1 through 9 {
      .py-color-#{$color}--#{$i * 10} {
        color: var(--py-color-#{$color}--#{$i * 10});
      }
      --py-color-#{$color}--#{$i * 10}: rgba(#{$red}, #{$green}, #{$blue}, #{calc($i / 10)});
    }

    --py-color-#{$color}: #{$value};
  }
}
