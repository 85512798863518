/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
:root {
  --puff-collection-background-paper: url("../assets-papyrus/images/paper-background-2.jpeg");
  --puff-collection-background-paper-webp: url("../assets-papyrus/images/paper-background-2.webp");
  --paragraph-sticker-2: url("../assets-papyrus/images/Papernote_BeHeard.png");
  --paragraph-sticker-2-webp: url("../assets-papyrus/images/Papernote_BeHeard.webp");
  --paragraph-sticker-3: url("../assets-papyrus/images/Papernote_KeepGrowing.png");
  --paragraph-sticker-3-webp: url("../assets-papyrus/images/Papernote_KeepGrowing.webp");
  --paragraph-sticker-4: url("../assets-papyrus/images/Papernote_beige.jpg");
  --paragraph-sticker-4-webp: url("../assets-papyrus/images/Papernote_beige.webp");
}

.flag-sprite {
  width: 32px;
  scale: 0.8;
}

.flag-sprite-Belgium {
  background: url(/assets-papyrus/images/flags/belgium.svg) no-repeat;
}

.flag-sprite-Czech-Republic {
  background: url(/assets-papyrus/images/flags/czech-republic.svg) no-repeat;
}

.flag-sprite-Denmark {
  background: url(/assets-papyrus/images/flags/denmark.svg) no-repeat;
}

.flag-sprite-Estonia {
  background: url(/assets-papyrus/images/flags/estonia.svg) no-repeat;
}

.flag-sprite-Finland {
  background: url(/assets-papyrus/images/flags/finland.svg) no-repeat;
}

.flag-sprite-Germany {
  background: url(/assets-papyrus/images/flags/germany.svg) no-repeat;
}

.flag-sprite-Hungary {
  background: url(/assets-papyrus/images/flags/hungary.svg) no-repeat;
}

.flag-sprite-Latvia {
  background: url(/assets-papyrus/images/flags/latvia.svg) no-repeat;
}

.flag-sprite-Lithuania {
  background: url(/assets-papyrus/images/flags/lithuania.svg) no-repeat;
}

.flag-sprite-Luxembourg {
  background: url(/assets-papyrus/images/flags/luxembourg.svg) no-repeat;
}

.flag-sprite-Netherlands {
  background: url(/assets-papyrus/images/flags/netherlands.svg) no-repeat;
}

.flag-sprite-Norway {
  background: url(/assets-papyrus/images/flags/norway.svg) no-repeat;
}

.flag-sprite-Poland {
  background: url(/assets-papyrus/images/flags/poland.svg) no-repeat;
}

.flag-sprite-Romania {
  background: url(/assets-papyrus/images/flags/romania.svg) no-repeat;
}

.flag-sprite-Sweden {
  background: url(/assets-papyrus/images/flags/sweden.svg) no-repeat;
}

.flag-sprite-Switzerland {
  background: url(/assets-papyrus/images/flags/switzerland.svg) no-repeat;
}

:root {
  --py-color-primary-900--10: rgba(3, 8, 16, 0.1);
  --py-color-primary-900--20: rgba(3, 8, 16, 0.2);
  --py-color-primary-900--30: rgba(3, 8, 16, 0.3);
  --py-color-primary-900--40: rgba(3, 8, 16, 0.4);
  --py-color-primary-900--50: rgba(3, 8, 16, 0.5);
  --py-color-primary-900--60: rgba(3, 8, 16, 0.6);
  --py-color-primary-900--70: rgba(3, 8, 16, 0.7);
  --py-color-primary-900--80: rgba(3, 8, 16, 0.8);
  --py-color-primary-900--90: rgba(3, 8, 16, 0.9);
  --py-color-primary-900: #030810;
  --py-color-primary-800--10: rgba(7, 16, 31, 0.1);
  --py-color-primary-800--20: rgba(7, 16, 31, 0.2);
  --py-color-primary-800--30: rgba(7, 16, 31, 0.3);
  --py-color-primary-800--40: rgba(7, 16, 31, 0.4);
  --py-color-primary-800--50: rgba(7, 16, 31, 0.5);
  --py-color-primary-800--60: rgba(7, 16, 31, 0.6);
  --py-color-primary-800--70: rgba(7, 16, 31, 0.7);
  --py-color-primary-800--80: rgba(7, 16, 31, 0.8);
  --py-color-primary-800--90: rgba(7, 16, 31, 0.9);
  --py-color-primary-800: #07101f;
  --py-color-primary-700--10: rgba(17, 40, 78, 0.1);
  --py-color-primary-700--20: rgba(17, 40, 78, 0.2);
  --py-color-primary-700--30: rgba(17, 40, 78, 0.3);
  --py-color-primary-700--40: rgba(17, 40, 78, 0.4);
  --py-color-primary-700--50: rgba(17, 40, 78, 0.5);
  --py-color-primary-700--60: rgba(17, 40, 78, 0.6);
  --py-color-primary-700--70: rgba(17, 40, 78, 0.7);
  --py-color-primary-700--80: rgba(17, 40, 78, 0.8);
  --py-color-primary-700--90: rgba(17, 40, 78, 0.9);
  --py-color-primary-700: #11284e;
  --py-color-primary-600--10: rgba(28, 64, 124, 0.1);
  --py-color-primary-600--20: rgba(28, 64, 124, 0.2);
  --py-color-primary-600--30: rgba(28, 64, 124, 0.3);
  --py-color-primary-600--40: rgba(28, 64, 124, 0.4);
  --py-color-primary-600--50: rgba(28, 64, 124, 0.5);
  --py-color-primary-600--60: rgba(28, 64, 124, 0.6);
  --py-color-primary-600--70: rgba(28, 64, 124, 0.7);
  --py-color-primary-600--80: rgba(28, 64, 124, 0.8);
  --py-color-primary-600--90: rgba(28, 64, 124, 0.9);
  --py-color-primary-600: #1c407c;
  --py-color-primary-500--10: rgba(35, 80, 155, 0.1);
  --py-color-primary-500--20: rgba(35, 80, 155, 0.2);
  --py-color-primary-500--30: rgba(35, 80, 155, 0.3);
  --py-color-primary-500--40: rgba(35, 80, 155, 0.4);
  --py-color-primary-500--50: rgba(35, 80, 155, 0.5);
  --py-color-primary-500--60: rgba(35, 80, 155, 0.6);
  --py-color-primary-500--70: rgba(35, 80, 155, 0.7);
  --py-color-primary-500--80: rgba(35, 80, 155, 0.8);
  --py-color-primary-500--90: rgba(35, 80, 155, 0.9);
  --py-color-primary-500: #23509b;
  --py-color-primary-400--10: rgba(79, 115, 175, 0.1);
  --py-color-primary-400--20: rgba(79, 115, 175, 0.2);
  --py-color-primary-400--30: rgba(79, 115, 175, 0.3);
  --py-color-primary-400--40: rgba(79, 115, 175, 0.4);
  --py-color-primary-400--50: rgba(79, 115, 175, 0.5);
  --py-color-primary-400--60: rgba(79, 115, 175, 0.6);
  --py-color-primary-400--70: rgba(79, 115, 175, 0.7);
  --py-color-primary-400--80: rgba(79, 115, 175, 0.8);
  --py-color-primary-400--90: rgba(79, 115, 175, 0.9);
  --py-color-primary-400: #4f73af;
  --py-color-primary-300--10: rgba(144, 167, 205, 0.1);
  --py-color-primary-300--20: rgba(144, 167, 205, 0.2);
  --py-color-primary-300--30: rgba(144, 167, 205, 0.3);
  --py-color-primary-300--40: rgba(144, 167, 205, 0.4);
  --py-color-primary-300--50: rgba(144, 167, 205, 0.5);
  --py-color-primary-300--60: rgba(144, 167, 205, 0.6);
  --py-color-primary-300--70: rgba(144, 167, 205, 0.7);
  --py-color-primary-300--80: rgba(144, 167, 205, 0.8);
  --py-color-primary-300--90: rgba(144, 167, 205, 0.9);
  --py-color-primary-300: #90a7cd;
  --py-color-primary-200--10: rgba(211, 220, 235, 0.1);
  --py-color-primary-200--20: rgba(211, 220, 235, 0.2);
  --py-color-primary-200--30: rgba(211, 220, 235, 0.3);
  --py-color-primary-200--40: rgba(211, 220, 235, 0.4);
  --py-color-primary-200--50: rgba(211, 220, 235, 0.5);
  --py-color-primary-200--60: rgba(211, 220, 235, 0.6);
  --py-color-primary-200--70: rgba(211, 220, 235, 0.7);
  --py-color-primary-200--80: rgba(211, 220, 235, 0.8);
  --py-color-primary-200--90: rgba(211, 220, 235, 0.9);
  --py-color-primary-200: #d3dceb;
  --py-color-primary-100--10: rgba(232, 237, 245, 0.1);
  --py-color-primary-100--20: rgba(232, 237, 245, 0.2);
  --py-color-primary-100--30: rgba(232, 237, 245, 0.3);
  --py-color-primary-100--40: rgba(232, 237, 245, 0.4);
  --py-color-primary-100--50: rgba(232, 237, 245, 0.5);
  --py-color-primary-100--60: rgba(232, 237, 245, 0.6);
  --py-color-primary-100--70: rgba(232, 237, 245, 0.7);
  --py-color-primary-100--80: rgba(232, 237, 245, 0.8);
  --py-color-primary-100--90: rgba(232, 237, 245, 0.9);
  --py-color-primary-100: #e8edf5;
  --py-color-secondary-900--10: rgba(3, 4, 8, 0.1);
  --py-color-secondary-900--20: rgba(3, 4, 8, 0.2);
  --py-color-secondary-900--30: rgba(3, 4, 8, 0.3);
  --py-color-secondary-900--40: rgba(3, 4, 8, 0.4);
  --py-color-secondary-900--50: rgba(3, 4, 8, 0.5);
  --py-color-secondary-900--60: rgba(3, 4, 8, 0.6);
  --py-color-secondary-900--70: rgba(3, 4, 8, 0.7);
  --py-color-secondary-900--80: rgba(3, 4, 8, 0.8);
  --py-color-secondary-900--90: rgba(3, 4, 8, 0.9);
  --py-color-secondary-900: #030408;
  --py-color-secondary-800--10: rgba(6, 8, 16, 0.1);
  --py-color-secondary-800--20: rgba(6, 8, 16, 0.2);
  --py-color-secondary-800--30: rgba(6, 8, 16, 0.3);
  --py-color-secondary-800--40: rgba(6, 8, 16, 0.4);
  --py-color-secondary-800--50: rgba(6, 8, 16, 0.5);
  --py-color-secondary-800--60: rgba(6, 8, 16, 0.6);
  --py-color-secondary-800--70: rgba(6, 8, 16, 0.7);
  --py-color-secondary-800--80: rgba(6, 8, 16, 0.8);
  --py-color-secondary-800--90: rgba(6, 8, 16, 0.9);
  --py-color-secondary-800: #060810;
  --py-color-secondary-700--10: rgba(15, 21, 39, 0.1);
  --py-color-secondary-700--20: rgba(15, 21, 39, 0.2);
  --py-color-secondary-700--30: rgba(15, 21, 39, 0.3);
  --py-color-secondary-700--40: rgba(15, 21, 39, 0.4);
  --py-color-secondary-700--50: rgba(15, 21, 39, 0.5);
  --py-color-secondary-700--60: rgba(15, 21, 39, 0.6);
  --py-color-secondary-700--70: rgba(15, 21, 39, 0.7);
  --py-color-secondary-700--80: rgba(15, 21, 39, 0.8);
  --py-color-secondary-700--90: rgba(15, 21, 39, 0.9);
  --py-color-secondary-700: #0f1527;
  --py-color-secondary-600--10: rgba(24, 34, 63, 0.1);
  --py-color-secondary-600--20: rgba(24, 34, 63, 0.2);
  --py-color-secondary-600--30: rgba(24, 34, 63, 0.3);
  --py-color-secondary-600--40: rgba(24, 34, 63, 0.4);
  --py-color-secondary-600--50: rgba(24, 34, 63, 0.5);
  --py-color-secondary-600--60: rgba(24, 34, 63, 0.6);
  --py-color-secondary-600--70: rgba(24, 34, 63, 0.7);
  --py-color-secondary-600--80: rgba(24, 34, 63, 0.8);
  --py-color-secondary-600--90: rgba(24, 34, 63, 0.9);
  --py-color-secondary-600: #18223f;
  --py-color-secondary-500--10: rgba(30, 43, 79, 0.1);
  --py-color-secondary-500--20: rgba(30, 43, 79, 0.2);
  --py-color-secondary-500--30: rgba(30, 43, 79, 0.3);
  --py-color-secondary-500--40: rgba(30, 43, 79, 0.4);
  --py-color-secondary-500--50: rgba(30, 43, 79, 0.5);
  --py-color-secondary-500--60: rgba(30, 43, 79, 0.6);
  --py-color-secondary-500--70: rgba(30, 43, 79, 0.7);
  --py-color-secondary-500--80: rgba(30, 43, 79, 0.8);
  --py-color-secondary-500--90: rgba(30, 43, 79, 0.9);
  --py-color-secondary-500: #1e2b4f;
  --py-color-secondary-400--10: rgba(75, 85, 114, 0.1);
  --py-color-secondary-400--20: rgba(75, 85, 114, 0.2);
  --py-color-secondary-400--30: rgba(75, 85, 114, 0.3);
  --py-color-secondary-400--40: rgba(75, 85, 114, 0.4);
  --py-color-secondary-400--50: rgba(75, 85, 114, 0.5);
  --py-color-secondary-400--60: rgba(75, 85, 114, 0.6);
  --py-color-secondary-400--70: rgba(75, 85, 114, 0.7);
  --py-color-secondary-400--80: rgba(75, 85, 114, 0.8);
  --py-color-secondary-400--90: rgba(75, 85, 114, 0.9);
  --py-color-secondary-400: #4b5572;
  --py-color-secondary-300--10: rgba(142, 148, 166, 0.1);
  --py-color-secondary-300--20: rgba(142, 148, 166, 0.2);
  --py-color-secondary-300--30: rgba(142, 148, 166, 0.3);
  --py-color-secondary-300--40: rgba(142, 148, 166, 0.4);
  --py-color-secondary-300--50: rgba(142, 148, 166, 0.5);
  --py-color-secondary-300--60: rgba(142, 148, 166, 0.6);
  --py-color-secondary-300--70: rgba(142, 148, 166, 0.7);
  --py-color-secondary-300--80: rgba(142, 148, 166, 0.8);
  --py-color-secondary-300--90: rgba(142, 148, 166, 0.9);
  --py-color-secondary-300: #8e94a6;
  --py-color-secondary-200--10: rgba(210, 212, 220, 0.1);
  --py-color-secondary-200--20: rgba(210, 212, 220, 0.2);
  --py-color-secondary-200--30: rgba(210, 212, 220, 0.3);
  --py-color-secondary-200--40: rgba(210, 212, 220, 0.4);
  --py-color-secondary-200--50: rgba(210, 212, 220, 0.5);
  --py-color-secondary-200--60: rgba(210, 212, 220, 0.6);
  --py-color-secondary-200--70: rgba(210, 212, 220, 0.7);
  --py-color-secondary-200--80: rgba(210, 212, 220, 0.8);
  --py-color-secondary-200--90: rgba(210, 212, 220, 0.9);
  --py-color-secondary-200: #d2d4dc;
  --py-color-secondary-100--10: rgba(232, 233, 237, 0.1);
  --py-color-secondary-100--20: rgba(232, 233, 237, 0.2);
  --py-color-secondary-100--30: rgba(232, 233, 237, 0.3);
  --py-color-secondary-100--40: rgba(232, 233, 237, 0.4);
  --py-color-secondary-100--50: rgba(232, 233, 237, 0.5);
  --py-color-secondary-100--60: rgba(232, 233, 237, 0.6);
  --py-color-secondary-100--70: rgba(232, 233, 237, 0.7);
  --py-color-secondary-100--80: rgba(232, 233, 237, 0.8);
  --py-color-secondary-100--90: rgba(232, 233, 237, 0.9);
  --py-color-secondary-100: #e8e9ed;
  --py-color-button-primary--10: rgba(35, 80, 155, 0.1);
  --py-color-button-primary--20: rgba(35, 80, 155, 0.2);
  --py-color-button-primary--30: rgba(35, 80, 155, 0.3);
  --py-color-button-primary--40: rgba(35, 80, 155, 0.4);
  --py-color-button-primary--50: rgba(35, 80, 155, 0.5);
  --py-color-button-primary--60: rgba(35, 80, 155, 0.6);
  --py-color-button-primary--70: rgba(35, 80, 155, 0.7);
  --py-color-button-primary--80: rgba(35, 80, 155, 0.8);
  --py-color-button-primary--90: rgba(35, 80, 155, 0.9);
  --py-color-button-primary: #23509b;
  --py-color-button-secondary--10: rgba(30, 43, 79, 0.1);
  --py-color-button-secondary--20: rgba(30, 43, 79, 0.2);
  --py-color-button-secondary--30: rgba(30, 43, 79, 0.3);
  --py-color-button-secondary--40: rgba(30, 43, 79, 0.4);
  --py-color-button-secondary--50: rgba(30, 43, 79, 0.5);
  --py-color-button-secondary--60: rgba(30, 43, 79, 0.6);
  --py-color-button-secondary--70: rgba(30, 43, 79, 0.7);
  --py-color-button-secondary--80: rgba(30, 43, 79, 0.8);
  --py-color-button-secondary--90: rgba(30, 43, 79, 0.9);
  --py-color-button-secondary: #1e2b4f;
  --py-color-button-danger--10: rgba(244, 63, 63, 0.1);
  --py-color-button-danger--20: rgba(244, 63, 63, 0.2);
  --py-color-button-danger--30: rgba(244, 63, 63, 0.3);
  --py-color-button-danger--40: rgba(244, 63, 63, 0.4);
  --py-color-button-danger--50: rgba(244, 63, 63, 0.5);
  --py-color-button-danger--60: rgba(244, 63, 63, 0.6);
  --py-color-button-danger--70: rgba(244, 63, 63, 0.7);
  --py-color-button-danger--80: rgba(244, 63, 63, 0.8);
  --py-color-button-danger--90: rgba(244, 63, 63, 0.9);
  --py-color-button-danger: #f43f3f;
  --py-color-button-locked--10: rgba(229, 229, 230, 0.1);
  --py-color-button-locked--20: rgba(229, 229, 230, 0.2);
  --py-color-button-locked--30: rgba(229, 229, 230, 0.3);
  --py-color-button-locked--40: rgba(229, 229, 230, 0.4);
  --py-color-button-locked--50: rgba(229, 229, 230, 0.5);
  --py-color-button-locked--60: rgba(229, 229, 230, 0.6);
  --py-color-button-locked--70: rgba(229, 229, 230, 0.7);
  --py-color-button-locked--80: rgba(229, 229, 230, 0.8);
  --py-color-button-locked--90: rgba(229, 229, 230, 0.9);
  --py-color-button-locked: #e5e5e6;
  --py-color-line-hard--10: rgba(130, 131, 133, 0.1);
  --py-color-line-hard--20: rgba(130, 131, 133, 0.2);
  --py-color-line-hard--30: rgba(130, 131, 133, 0.3);
  --py-color-line-hard--40: rgba(130, 131, 133, 0.4);
  --py-color-line-hard--50: rgba(130, 131, 133, 0.5);
  --py-color-line-hard--60: rgba(130, 131, 133, 0.6);
  --py-color-line-hard--70: rgba(130, 131, 133, 0.7);
  --py-color-line-hard--80: rgba(130, 131, 133, 0.8);
  --py-color-line-hard--90: rgba(130, 131, 133, 0.9);
  --py-color-line-hard: #828385;
  --py-color-line-steady--10: rgba(203, 203, 205, 0.1);
  --py-color-line-steady--20: rgba(203, 203, 205, 0.2);
  --py-color-line-steady--30: rgba(203, 203, 205, 0.3);
  --py-color-line-steady--40: rgba(203, 203, 205, 0.4);
  --py-color-line-steady--50: rgba(203, 203, 205, 0.5);
  --py-color-line-steady--60: rgba(203, 203, 205, 0.6);
  --py-color-line-steady--70: rgba(203, 203, 205, 0.7);
  --py-color-line-steady--80: rgba(203, 203, 205, 0.8);
  --py-color-line-steady--90: rgba(203, 203, 205, 0.9);
  --py-color-line-steady: #cbcbcd;
  --py-color-line-soft--10: rgba(229, 229, 230, 0.1);
  --py-color-line-soft--20: rgba(229, 229, 230, 0.2);
  --py-color-line-soft--30: rgba(229, 229, 230, 0.3);
  --py-color-line-soft--40: rgba(229, 229, 230, 0.4);
  --py-color-line-soft--50: rgba(229, 229, 230, 0.5);
  --py-color-line-soft--60: rgba(229, 229, 230, 0.6);
  --py-color-line-soft--70: rgba(229, 229, 230, 0.7);
  --py-color-line-soft--80: rgba(229, 229, 230, 0.8);
  --py-color-line-soft--90: rgba(229, 229, 230, 0.9);
  --py-color-line-soft: #e5e5e6;
  --py-color-global-selected--10: rgba(232, 237, 245, 0.1);
  --py-color-global-selected--20: rgba(232, 237, 245, 0.2);
  --py-color-global-selected--30: rgba(232, 237, 245, 0.3);
  --py-color-global-selected--40: rgba(232, 237, 245, 0.4);
  --py-color-global-selected--50: rgba(232, 237, 245, 0.5);
  --py-color-global-selected--60: rgba(232, 237, 245, 0.6);
  --py-color-global-selected--70: rgba(232, 237, 245, 0.7);
  --py-color-global-selected--80: rgba(232, 237, 245, 0.8);
  --py-color-global-selected--90: rgba(232, 237, 245, 0.9);
  --py-color-global-selected: #e8edf5;
  --py-color-global-hover--10: rgba(211, 220, 235, 0.1);
  --py-color-global-hover--20: rgba(211, 220, 235, 0.2);
  --py-color-global-hover--30: rgba(211, 220, 235, 0.3);
  --py-color-global-hover--40: rgba(211, 220, 235, 0.4);
  --py-color-global-hover--50: rgba(211, 220, 235, 0.5);
  --py-color-global-hover--60: rgba(211, 220, 235, 0.6);
  --py-color-global-hover--70: rgba(211, 220, 235, 0.7);
  --py-color-global-hover--80: rgba(211, 220, 235, 0.8);
  --py-color-global-hover--90: rgba(211, 220, 235, 0.9);
  --py-color-global-hover: #d3dceb;
  --py-color-global-active--10: rgba(35, 80, 155, 0.1);
  --py-color-global-active--20: rgba(35, 80, 155, 0.2);
  --py-color-global-active--30: rgba(35, 80, 155, 0.3);
  --py-color-global-active--40: rgba(35, 80, 155, 0.4);
  --py-color-global-active--50: rgba(35, 80, 155, 0.5);
  --py-color-global-active--60: rgba(35, 80, 155, 0.6);
  --py-color-global-active--70: rgba(35, 80, 155, 0.7);
  --py-color-global-active--80: rgba(35, 80, 155, 0.8);
  --py-color-global-active--90: rgba(35, 80, 155, 0.9);
  --py-color-global-active: #23509b;
  --py-color-global-active-hover--10: rgba(17, 40, 78, 0.1);
  --py-color-global-active-hover--20: rgba(17, 40, 78, 0.2);
  --py-color-global-active-hover--30: rgba(17, 40, 78, 0.3);
  --py-color-global-active-hover--40: rgba(17, 40, 78, 0.4);
  --py-color-global-active-hover--50: rgba(17, 40, 78, 0.5);
  --py-color-global-active-hover--60: rgba(17, 40, 78, 0.6);
  --py-color-global-active-hover--70: rgba(17, 40, 78, 0.7);
  --py-color-global-active-hover--80: rgba(17, 40, 78, 0.8);
  --py-color-global-active-hover--90: rgba(17, 40, 78, 0.9);
  --py-color-global-active-hover: #11284e;
  --py-color-global-tooltip--10: rgba(100, 101, 104, 0.1);
  --py-color-global-tooltip--20: rgba(100, 101, 104, 0.2);
  --py-color-global-tooltip--30: rgba(100, 101, 104, 0.3);
  --py-color-global-tooltip--40: rgba(100, 101, 104, 0.4);
  --py-color-global-tooltip--50: rgba(100, 101, 104, 0.5);
  --py-color-global-tooltip--60: rgba(100, 101, 104, 0.6);
  --py-color-global-tooltip--70: rgba(100, 101, 104, 0.7);
  --py-color-global-tooltip--80: rgba(100, 101, 104, 0.8);
  --py-color-global-tooltip--90: rgba(100, 101, 104, 0.9);
  --py-color-global-tooltip: #646568;
  --py-color-banner-collection-blocks-1--10: rgba(162, 195, 205, 0.1);
  --py-color-banner-collection-blocks-1--20: rgba(162, 195, 205, 0.2);
  --py-color-banner-collection-blocks-1--30: rgba(162, 195, 205, 0.3);
  --py-color-banner-collection-blocks-1--40: rgba(162, 195, 205, 0.4);
  --py-color-banner-collection-blocks-1--50: rgba(162, 195, 205, 0.5);
  --py-color-banner-collection-blocks-1--60: rgba(162, 195, 205, 0.6);
  --py-color-banner-collection-blocks-1--70: rgba(162, 195, 205, 0.7);
  --py-color-banner-collection-blocks-1--80: rgba(162, 195, 205, 0.8);
  --py-color-banner-collection-blocks-1--90: rgba(162, 195, 205, 0.9);
  --py-color-banner-collection-blocks-1: #a2c3cd;
  --py-color-banner-collection-blocks-2--10: rgba(218, 230, 234, 0.1);
  --py-color-banner-collection-blocks-2--20: rgba(218, 230, 234, 0.2);
  --py-color-banner-collection-blocks-2--30: rgba(218, 230, 234, 0.3);
  --py-color-banner-collection-blocks-2--40: rgba(218, 230, 234, 0.4);
  --py-color-banner-collection-blocks-2--50: rgba(218, 230, 234, 0.5);
  --py-color-banner-collection-blocks-2--60: rgba(218, 230, 234, 0.6);
  --py-color-banner-collection-blocks-2--70: rgba(218, 230, 234, 0.7);
  --py-color-banner-collection-blocks-2--80: rgba(218, 230, 234, 0.8);
  --py-color-banner-collection-blocks-2--90: rgba(218, 230, 234, 0.9);
  --py-color-banner-collection-blocks-2: #dae6ea;
  --py-color-banner-collection-blocks-3--10: rgba(77, 135, 153, 0.1);
  --py-color-banner-collection-blocks-3--20: rgba(77, 135, 153, 0.2);
  --py-color-banner-collection-blocks-3--30: rgba(77, 135, 153, 0.3);
  --py-color-banner-collection-blocks-3--40: rgba(77, 135, 153, 0.4);
  --py-color-banner-collection-blocks-3--50: rgba(77, 135, 153, 0.5);
  --py-color-banner-collection-blocks-3--60: rgba(77, 135, 153, 0.6);
  --py-color-banner-collection-blocks-3--70: rgba(77, 135, 153, 0.7);
  --py-color-banner-collection-blocks-3--80: rgba(77, 135, 153, 0.8);
  --py-color-banner-collection-blocks-3--90: rgba(77, 135, 153, 0.9);
  --py-color-banner-collection-blocks-3: #4d8799;
  --py-color-primary-dark--10: rgba(35, 80, 155, 0.1);
  --py-color-primary-dark--20: rgba(35, 80, 155, 0.2);
  --py-color-primary-dark--30: rgba(35, 80, 155, 0.3);
  --py-color-primary-dark--40: rgba(35, 80, 155, 0.4);
  --py-color-primary-dark--50: rgba(35, 80, 155, 0.5);
  --py-color-primary-dark--60: rgba(35, 80, 155, 0.6);
  --py-color-primary-dark--70: rgba(35, 80, 155, 0.7);
  --py-color-primary-dark--80: rgba(35, 80, 155, 0.8);
  --py-color-primary-dark--90: rgba(35, 80, 155, 0.9);
  --py-color-primary-dark: #23509b;
  --py-color-primary--10: rgba(79, 115, 175, 0.1);
  --py-color-primary--20: rgba(79, 115, 175, 0.2);
  --py-color-primary--30: rgba(79, 115, 175, 0.3);
  --py-color-primary--40: rgba(79, 115, 175, 0.4);
  --py-color-primary--50: rgba(79, 115, 175, 0.5);
  --py-color-primary--60: rgba(79, 115, 175, 0.6);
  --py-color-primary--70: rgba(79, 115, 175, 0.7);
  --py-color-primary--80: rgba(79, 115, 175, 0.8);
  --py-color-primary--90: rgba(79, 115, 175, 0.9);
  --py-color-primary: #4f73af;
  --py-color-primary-light--10: rgba(144, 167, 205, 0.1);
  --py-color-primary-light--20: rgba(144, 167, 205, 0.2);
  --py-color-primary-light--30: rgba(144, 167, 205, 0.3);
  --py-color-primary-light--40: rgba(144, 167, 205, 0.4);
  --py-color-primary-light--50: rgba(144, 167, 205, 0.5);
  --py-color-primary-light--60: rgba(144, 167, 205, 0.6);
  --py-color-primary-light--70: rgba(144, 167, 205, 0.7);
  --py-color-primary-light--80: rgba(144, 167, 205, 0.8);
  --py-color-primary-light--90: rgba(144, 167, 205, 0.9);
  --py-color-primary-light: #90a7cd;
  --py-color-secondary-darker--10: rgba(79, 115, 175, 0.1);
  --py-color-secondary-darker--20: rgba(79, 115, 175, 0.2);
  --py-color-secondary-darker--30: rgba(79, 115, 175, 0.3);
  --py-color-secondary-darker--40: rgba(79, 115, 175, 0.4);
  --py-color-secondary-darker--50: rgba(79, 115, 175, 0.5);
  --py-color-secondary-darker--60: rgba(79, 115, 175, 0.6);
  --py-color-secondary-darker--70: rgba(79, 115, 175, 0.7);
  --py-color-secondary-darker--80: rgba(79, 115, 175, 0.8);
  --py-color-secondary-darker--90: rgba(79, 115, 175, 0.9);
  --py-color-secondary-darker: #4f73af;
  --py-color-secondary-dark--10: rgba(144, 167, 205, 0.1);
  --py-color-secondary-dark--20: rgba(144, 167, 205, 0.2);
  --py-color-secondary-dark--30: rgba(144, 167, 205, 0.3);
  --py-color-secondary-dark--40: rgba(144, 167, 205, 0.4);
  --py-color-secondary-dark--50: rgba(144, 167, 205, 0.5);
  --py-color-secondary-dark--60: rgba(144, 167, 205, 0.6);
  --py-color-secondary-dark--70: rgba(144, 167, 205, 0.7);
  --py-color-secondary-dark--80: rgba(144, 167, 205, 0.8);
  --py-color-secondary-dark--90: rgba(144, 167, 205, 0.9);
  --py-color-secondary-dark: #90a7cd;
  --py-color-secondary--10: rgba(35, 80, 155, 0.1);
  --py-color-secondary--20: rgba(35, 80, 155, 0.2);
  --py-color-secondary--30: rgba(35, 80, 155, 0.3);
  --py-color-secondary--40: rgba(35, 80, 155, 0.4);
  --py-color-secondary--50: rgba(35, 80, 155, 0.5);
  --py-color-secondary--60: rgba(35, 80, 155, 0.6);
  --py-color-secondary--70: rgba(35, 80, 155, 0.7);
  --py-color-secondary--80: rgba(35, 80, 155, 0.8);
  --py-color-secondary--90: rgba(35, 80, 155, 0.9);
  --py-color-secondary: #23509b;
  --py-color-secondary-light--10: rgba(211, 220, 235, 0.1);
  --py-color-secondary-light--20: rgba(211, 220, 235, 0.2);
  --py-color-secondary-light--30: rgba(211, 220, 235, 0.3);
  --py-color-secondary-light--40: rgba(211, 220, 235, 0.4);
  --py-color-secondary-light--50: rgba(211, 220, 235, 0.5);
  --py-color-secondary-light--60: rgba(211, 220, 235, 0.6);
  --py-color-secondary-light--70: rgba(211, 220, 235, 0.7);
  --py-color-secondary-light--80: rgba(211, 220, 235, 0.8);
  --py-color-secondary-light--90: rgba(211, 220, 235, 0.9);
  --py-color-secondary-light: #d3dceb;
  --py-color-grey-light--10: rgba(229, 229, 230, 0.1);
  --py-color-grey-light--20: rgba(229, 229, 230, 0.2);
  --py-color-grey-light--30: rgba(229, 229, 230, 0.3);
  --py-color-grey-light--40: rgba(229, 229, 230, 0.4);
  --py-color-grey-light--50: rgba(229, 229, 230, 0.5);
  --py-color-grey-light--60: rgba(229, 229, 230, 0.6);
  --py-color-grey-light--70: rgba(229, 229, 230, 0.7);
  --py-color-grey-light--80: rgba(229, 229, 230, 0.8);
  --py-color-grey-light--90: rgba(229, 229, 230, 0.9);
  --py-color-grey-light: #e5e5e6;
  --py-color-grey-lighter--10: rgba(242, 242, 242, 0.1);
  --py-color-grey-lighter--20: rgba(242, 242, 242, 0.2);
  --py-color-grey-lighter--30: rgba(242, 242, 242, 0.3);
  --py-color-grey-lighter--40: rgba(242, 242, 242, 0.4);
  --py-color-grey-lighter--50: rgba(242, 242, 242, 0.5);
  --py-color-grey-lighter--60: rgba(242, 242, 242, 0.6);
  --py-color-grey-lighter--70: rgba(242, 242, 242, 0.7);
  --py-color-grey-lighter--80: rgba(242, 242, 242, 0.8);
  --py-color-grey-lighter--90: rgba(242, 242, 242, 0.9);
  --py-color-grey-lighter: #f2f2f2;
  --py-color-success--10: rgba(146, 193, 57, 0.1);
  --py-color-success--20: rgba(146, 193, 57, 0.2);
  --py-color-success--30: rgba(146, 193, 57, 0.3);
  --py-color-success--40: rgba(146, 193, 57, 0.4);
  --py-color-success--50: rgba(146, 193, 57, 0.5);
  --py-color-success--60: rgba(146, 193, 57, 0.6);
  --py-color-success--70: rgba(146, 193, 57, 0.7);
  --py-color-success--80: rgba(146, 193, 57, 0.8);
  --py-color-success--90: rgba(146, 193, 57, 0.9);
  --py-color-success: #92c139;
  --py-color-warning--10: rgba(255, 193, 86, 0.1);
  --py-color-warning--20: rgba(255, 193, 86, 0.2);
  --py-color-warning--30: rgba(255, 193, 86, 0.3);
  --py-color-warning--40: rgba(255, 193, 86, 0.4);
  --py-color-warning--50: rgba(255, 193, 86, 0.5);
  --py-color-warning--60: rgba(255, 193, 86, 0.6);
  --py-color-warning--70: rgba(255, 193, 86, 0.7);
  --py-color-warning--80: rgba(255, 193, 86, 0.8);
  --py-color-warning--90: rgba(255, 193, 86, 0.9);
  --py-color-warning: #ffc156;
  --py-color-info--10: rgba(79, 115, 175, 0.1);
  --py-color-info--20: rgba(79, 115, 175, 0.2);
  --py-color-info--30: rgba(79, 115, 175, 0.3);
  --py-color-info--40: rgba(79, 115, 175, 0.4);
  --py-color-info--50: rgba(79, 115, 175, 0.5);
  --py-color-info--60: rgba(79, 115, 175, 0.6);
  --py-color-info--70: rgba(79, 115, 175, 0.7);
  --py-color-info--80: rgba(79, 115, 175, 0.8);
  --py-color-info--90: rgba(79, 115, 175, 0.9);
  --py-color-info: #4f73af;
}
:root .py-color-primary-900 {
  color: var(--py-color-primary-900);
}
:root .py-color-primary-900--10 {
  color: var(--py-color-primary-900--10);
}
:root .py-color-primary-900--20 {
  color: var(--py-color-primary-900--20);
}
:root .py-color-primary-900--30 {
  color: var(--py-color-primary-900--30);
}
:root .py-color-primary-900--40 {
  color: var(--py-color-primary-900--40);
}
:root .py-color-primary-900--50 {
  color: var(--py-color-primary-900--50);
}
:root .py-color-primary-900--60 {
  color: var(--py-color-primary-900--60);
}
:root .py-color-primary-900--70 {
  color: var(--py-color-primary-900--70);
}
:root .py-color-primary-900--80 {
  color: var(--py-color-primary-900--80);
}
:root .py-color-primary-900--90 {
  color: var(--py-color-primary-900--90);
}
:root .py-color-primary-800 {
  color: var(--py-color-primary-800);
}
:root .py-color-primary-800--10 {
  color: var(--py-color-primary-800--10);
}
:root .py-color-primary-800--20 {
  color: var(--py-color-primary-800--20);
}
:root .py-color-primary-800--30 {
  color: var(--py-color-primary-800--30);
}
:root .py-color-primary-800--40 {
  color: var(--py-color-primary-800--40);
}
:root .py-color-primary-800--50 {
  color: var(--py-color-primary-800--50);
}
:root .py-color-primary-800--60 {
  color: var(--py-color-primary-800--60);
}
:root .py-color-primary-800--70 {
  color: var(--py-color-primary-800--70);
}
:root .py-color-primary-800--80 {
  color: var(--py-color-primary-800--80);
}
:root .py-color-primary-800--90 {
  color: var(--py-color-primary-800--90);
}
:root .py-color-primary-700 {
  color: var(--py-color-primary-700);
}
:root .py-color-primary-700--10 {
  color: var(--py-color-primary-700--10);
}
:root .py-color-primary-700--20 {
  color: var(--py-color-primary-700--20);
}
:root .py-color-primary-700--30 {
  color: var(--py-color-primary-700--30);
}
:root .py-color-primary-700--40 {
  color: var(--py-color-primary-700--40);
}
:root .py-color-primary-700--50 {
  color: var(--py-color-primary-700--50);
}
:root .py-color-primary-700--60 {
  color: var(--py-color-primary-700--60);
}
:root .py-color-primary-700--70 {
  color: var(--py-color-primary-700--70);
}
:root .py-color-primary-700--80 {
  color: var(--py-color-primary-700--80);
}
:root .py-color-primary-700--90 {
  color: var(--py-color-primary-700--90);
}
:root .py-color-primary-600 {
  color: var(--py-color-primary-600);
}
:root .py-color-primary-600--10 {
  color: var(--py-color-primary-600--10);
}
:root .py-color-primary-600--20 {
  color: var(--py-color-primary-600--20);
}
:root .py-color-primary-600--30 {
  color: var(--py-color-primary-600--30);
}
:root .py-color-primary-600--40 {
  color: var(--py-color-primary-600--40);
}
:root .py-color-primary-600--50 {
  color: var(--py-color-primary-600--50);
}
:root .py-color-primary-600--60 {
  color: var(--py-color-primary-600--60);
}
:root .py-color-primary-600--70 {
  color: var(--py-color-primary-600--70);
}
:root .py-color-primary-600--80 {
  color: var(--py-color-primary-600--80);
}
:root .py-color-primary-600--90 {
  color: var(--py-color-primary-600--90);
}
:root .py-color-primary-500 {
  color: var(--py-color-primary-500);
}
:root .py-color-primary-500--10 {
  color: var(--py-color-primary-500--10);
}
:root .py-color-primary-500--20 {
  color: var(--py-color-primary-500--20);
}
:root .py-color-primary-500--30 {
  color: var(--py-color-primary-500--30);
}
:root .py-color-primary-500--40 {
  color: var(--py-color-primary-500--40);
}
:root .py-color-primary-500--50 {
  color: var(--py-color-primary-500--50);
}
:root .py-color-primary-500--60 {
  color: var(--py-color-primary-500--60);
}
:root .py-color-primary-500--70 {
  color: var(--py-color-primary-500--70);
}
:root .py-color-primary-500--80 {
  color: var(--py-color-primary-500--80);
}
:root .py-color-primary-500--90 {
  color: var(--py-color-primary-500--90);
}
:root .py-color-primary-400 {
  color: var(--py-color-primary-400);
}
:root .py-color-primary-400--10 {
  color: var(--py-color-primary-400--10);
}
:root .py-color-primary-400--20 {
  color: var(--py-color-primary-400--20);
}
:root .py-color-primary-400--30 {
  color: var(--py-color-primary-400--30);
}
:root .py-color-primary-400--40 {
  color: var(--py-color-primary-400--40);
}
:root .py-color-primary-400--50 {
  color: var(--py-color-primary-400--50);
}
:root .py-color-primary-400--60 {
  color: var(--py-color-primary-400--60);
}
:root .py-color-primary-400--70 {
  color: var(--py-color-primary-400--70);
}
:root .py-color-primary-400--80 {
  color: var(--py-color-primary-400--80);
}
:root .py-color-primary-400--90 {
  color: var(--py-color-primary-400--90);
}
:root .py-color-primary-300 {
  color: var(--py-color-primary-300);
}
:root .py-color-primary-300--10 {
  color: var(--py-color-primary-300--10);
}
:root .py-color-primary-300--20 {
  color: var(--py-color-primary-300--20);
}
:root .py-color-primary-300--30 {
  color: var(--py-color-primary-300--30);
}
:root .py-color-primary-300--40 {
  color: var(--py-color-primary-300--40);
}
:root .py-color-primary-300--50 {
  color: var(--py-color-primary-300--50);
}
:root .py-color-primary-300--60 {
  color: var(--py-color-primary-300--60);
}
:root .py-color-primary-300--70 {
  color: var(--py-color-primary-300--70);
}
:root .py-color-primary-300--80 {
  color: var(--py-color-primary-300--80);
}
:root .py-color-primary-300--90 {
  color: var(--py-color-primary-300--90);
}
:root .py-color-primary-200 {
  color: var(--py-color-primary-200);
}
:root .py-color-primary-200--10 {
  color: var(--py-color-primary-200--10);
}
:root .py-color-primary-200--20 {
  color: var(--py-color-primary-200--20);
}
:root .py-color-primary-200--30 {
  color: var(--py-color-primary-200--30);
}
:root .py-color-primary-200--40 {
  color: var(--py-color-primary-200--40);
}
:root .py-color-primary-200--50 {
  color: var(--py-color-primary-200--50);
}
:root .py-color-primary-200--60 {
  color: var(--py-color-primary-200--60);
}
:root .py-color-primary-200--70 {
  color: var(--py-color-primary-200--70);
}
:root .py-color-primary-200--80 {
  color: var(--py-color-primary-200--80);
}
:root .py-color-primary-200--90 {
  color: var(--py-color-primary-200--90);
}
:root .py-color-primary-100 {
  color: var(--py-color-primary-100);
}
:root .py-color-primary-100--10 {
  color: var(--py-color-primary-100--10);
}
:root .py-color-primary-100--20 {
  color: var(--py-color-primary-100--20);
}
:root .py-color-primary-100--30 {
  color: var(--py-color-primary-100--30);
}
:root .py-color-primary-100--40 {
  color: var(--py-color-primary-100--40);
}
:root .py-color-primary-100--50 {
  color: var(--py-color-primary-100--50);
}
:root .py-color-primary-100--60 {
  color: var(--py-color-primary-100--60);
}
:root .py-color-primary-100--70 {
  color: var(--py-color-primary-100--70);
}
:root .py-color-primary-100--80 {
  color: var(--py-color-primary-100--80);
}
:root .py-color-primary-100--90 {
  color: var(--py-color-primary-100--90);
}
:root .py-color-secondary-900 {
  color: var(--py-color-secondary-900);
}
:root .py-color-secondary-900--10 {
  color: var(--py-color-secondary-900--10);
}
:root .py-color-secondary-900--20 {
  color: var(--py-color-secondary-900--20);
}
:root .py-color-secondary-900--30 {
  color: var(--py-color-secondary-900--30);
}
:root .py-color-secondary-900--40 {
  color: var(--py-color-secondary-900--40);
}
:root .py-color-secondary-900--50 {
  color: var(--py-color-secondary-900--50);
}
:root .py-color-secondary-900--60 {
  color: var(--py-color-secondary-900--60);
}
:root .py-color-secondary-900--70 {
  color: var(--py-color-secondary-900--70);
}
:root .py-color-secondary-900--80 {
  color: var(--py-color-secondary-900--80);
}
:root .py-color-secondary-900--90 {
  color: var(--py-color-secondary-900--90);
}
:root .py-color-secondary-800 {
  color: var(--py-color-secondary-800);
}
:root .py-color-secondary-800--10 {
  color: var(--py-color-secondary-800--10);
}
:root .py-color-secondary-800--20 {
  color: var(--py-color-secondary-800--20);
}
:root .py-color-secondary-800--30 {
  color: var(--py-color-secondary-800--30);
}
:root .py-color-secondary-800--40 {
  color: var(--py-color-secondary-800--40);
}
:root .py-color-secondary-800--50 {
  color: var(--py-color-secondary-800--50);
}
:root .py-color-secondary-800--60 {
  color: var(--py-color-secondary-800--60);
}
:root .py-color-secondary-800--70 {
  color: var(--py-color-secondary-800--70);
}
:root .py-color-secondary-800--80 {
  color: var(--py-color-secondary-800--80);
}
:root .py-color-secondary-800--90 {
  color: var(--py-color-secondary-800--90);
}
:root .py-color-secondary-700 {
  color: var(--py-color-secondary-700);
}
:root .py-color-secondary-700--10 {
  color: var(--py-color-secondary-700--10);
}
:root .py-color-secondary-700--20 {
  color: var(--py-color-secondary-700--20);
}
:root .py-color-secondary-700--30 {
  color: var(--py-color-secondary-700--30);
}
:root .py-color-secondary-700--40 {
  color: var(--py-color-secondary-700--40);
}
:root .py-color-secondary-700--50 {
  color: var(--py-color-secondary-700--50);
}
:root .py-color-secondary-700--60 {
  color: var(--py-color-secondary-700--60);
}
:root .py-color-secondary-700--70 {
  color: var(--py-color-secondary-700--70);
}
:root .py-color-secondary-700--80 {
  color: var(--py-color-secondary-700--80);
}
:root .py-color-secondary-700--90 {
  color: var(--py-color-secondary-700--90);
}
:root .py-color-secondary-600 {
  color: var(--py-color-secondary-600);
}
:root .py-color-secondary-600--10 {
  color: var(--py-color-secondary-600--10);
}
:root .py-color-secondary-600--20 {
  color: var(--py-color-secondary-600--20);
}
:root .py-color-secondary-600--30 {
  color: var(--py-color-secondary-600--30);
}
:root .py-color-secondary-600--40 {
  color: var(--py-color-secondary-600--40);
}
:root .py-color-secondary-600--50 {
  color: var(--py-color-secondary-600--50);
}
:root .py-color-secondary-600--60 {
  color: var(--py-color-secondary-600--60);
}
:root .py-color-secondary-600--70 {
  color: var(--py-color-secondary-600--70);
}
:root .py-color-secondary-600--80 {
  color: var(--py-color-secondary-600--80);
}
:root .py-color-secondary-600--90 {
  color: var(--py-color-secondary-600--90);
}
:root .py-color-secondary-500 {
  color: var(--py-color-secondary-500);
}
:root .py-color-secondary-500--10 {
  color: var(--py-color-secondary-500--10);
}
:root .py-color-secondary-500--20 {
  color: var(--py-color-secondary-500--20);
}
:root .py-color-secondary-500--30 {
  color: var(--py-color-secondary-500--30);
}
:root .py-color-secondary-500--40 {
  color: var(--py-color-secondary-500--40);
}
:root .py-color-secondary-500--50 {
  color: var(--py-color-secondary-500--50);
}
:root .py-color-secondary-500--60 {
  color: var(--py-color-secondary-500--60);
}
:root .py-color-secondary-500--70 {
  color: var(--py-color-secondary-500--70);
}
:root .py-color-secondary-500--80 {
  color: var(--py-color-secondary-500--80);
}
:root .py-color-secondary-500--90 {
  color: var(--py-color-secondary-500--90);
}
:root .py-color-secondary-400 {
  color: var(--py-color-secondary-400);
}
:root .py-color-secondary-400--10 {
  color: var(--py-color-secondary-400--10);
}
:root .py-color-secondary-400--20 {
  color: var(--py-color-secondary-400--20);
}
:root .py-color-secondary-400--30 {
  color: var(--py-color-secondary-400--30);
}
:root .py-color-secondary-400--40 {
  color: var(--py-color-secondary-400--40);
}
:root .py-color-secondary-400--50 {
  color: var(--py-color-secondary-400--50);
}
:root .py-color-secondary-400--60 {
  color: var(--py-color-secondary-400--60);
}
:root .py-color-secondary-400--70 {
  color: var(--py-color-secondary-400--70);
}
:root .py-color-secondary-400--80 {
  color: var(--py-color-secondary-400--80);
}
:root .py-color-secondary-400--90 {
  color: var(--py-color-secondary-400--90);
}
:root .py-color-secondary-300 {
  color: var(--py-color-secondary-300);
}
:root .py-color-secondary-300--10 {
  color: var(--py-color-secondary-300--10);
}
:root .py-color-secondary-300--20 {
  color: var(--py-color-secondary-300--20);
}
:root .py-color-secondary-300--30 {
  color: var(--py-color-secondary-300--30);
}
:root .py-color-secondary-300--40 {
  color: var(--py-color-secondary-300--40);
}
:root .py-color-secondary-300--50 {
  color: var(--py-color-secondary-300--50);
}
:root .py-color-secondary-300--60 {
  color: var(--py-color-secondary-300--60);
}
:root .py-color-secondary-300--70 {
  color: var(--py-color-secondary-300--70);
}
:root .py-color-secondary-300--80 {
  color: var(--py-color-secondary-300--80);
}
:root .py-color-secondary-300--90 {
  color: var(--py-color-secondary-300--90);
}
:root .py-color-secondary-200 {
  color: var(--py-color-secondary-200);
}
:root .py-color-secondary-200--10 {
  color: var(--py-color-secondary-200--10);
}
:root .py-color-secondary-200--20 {
  color: var(--py-color-secondary-200--20);
}
:root .py-color-secondary-200--30 {
  color: var(--py-color-secondary-200--30);
}
:root .py-color-secondary-200--40 {
  color: var(--py-color-secondary-200--40);
}
:root .py-color-secondary-200--50 {
  color: var(--py-color-secondary-200--50);
}
:root .py-color-secondary-200--60 {
  color: var(--py-color-secondary-200--60);
}
:root .py-color-secondary-200--70 {
  color: var(--py-color-secondary-200--70);
}
:root .py-color-secondary-200--80 {
  color: var(--py-color-secondary-200--80);
}
:root .py-color-secondary-200--90 {
  color: var(--py-color-secondary-200--90);
}
:root .py-color-secondary-100 {
  color: var(--py-color-secondary-100);
}
:root .py-color-secondary-100--10 {
  color: var(--py-color-secondary-100--10);
}
:root .py-color-secondary-100--20 {
  color: var(--py-color-secondary-100--20);
}
:root .py-color-secondary-100--30 {
  color: var(--py-color-secondary-100--30);
}
:root .py-color-secondary-100--40 {
  color: var(--py-color-secondary-100--40);
}
:root .py-color-secondary-100--50 {
  color: var(--py-color-secondary-100--50);
}
:root .py-color-secondary-100--60 {
  color: var(--py-color-secondary-100--60);
}
:root .py-color-secondary-100--70 {
  color: var(--py-color-secondary-100--70);
}
:root .py-color-secondary-100--80 {
  color: var(--py-color-secondary-100--80);
}
:root .py-color-secondary-100--90 {
  color: var(--py-color-secondary-100--90);
}
:root .py-color-button-primary {
  color: var(--py-color-button-primary);
}
:root .py-color-button-primary--10 {
  color: var(--py-color-button-primary--10);
}
:root .py-color-button-primary--20 {
  color: var(--py-color-button-primary--20);
}
:root .py-color-button-primary--30 {
  color: var(--py-color-button-primary--30);
}
:root .py-color-button-primary--40 {
  color: var(--py-color-button-primary--40);
}
:root .py-color-button-primary--50 {
  color: var(--py-color-button-primary--50);
}
:root .py-color-button-primary--60 {
  color: var(--py-color-button-primary--60);
}
:root .py-color-button-primary--70 {
  color: var(--py-color-button-primary--70);
}
:root .py-color-button-primary--80 {
  color: var(--py-color-button-primary--80);
}
:root .py-color-button-primary--90 {
  color: var(--py-color-button-primary--90);
}
:root .py-color-button-secondary {
  color: var(--py-color-button-secondary);
}
:root .py-color-button-secondary--10 {
  color: var(--py-color-button-secondary--10);
}
:root .py-color-button-secondary--20 {
  color: var(--py-color-button-secondary--20);
}
:root .py-color-button-secondary--30 {
  color: var(--py-color-button-secondary--30);
}
:root .py-color-button-secondary--40 {
  color: var(--py-color-button-secondary--40);
}
:root .py-color-button-secondary--50 {
  color: var(--py-color-button-secondary--50);
}
:root .py-color-button-secondary--60 {
  color: var(--py-color-button-secondary--60);
}
:root .py-color-button-secondary--70 {
  color: var(--py-color-button-secondary--70);
}
:root .py-color-button-secondary--80 {
  color: var(--py-color-button-secondary--80);
}
:root .py-color-button-secondary--90 {
  color: var(--py-color-button-secondary--90);
}
:root .py-color-button-danger {
  color: var(--py-color-button-danger);
}
:root .py-color-button-danger--10 {
  color: var(--py-color-button-danger--10);
}
:root .py-color-button-danger--20 {
  color: var(--py-color-button-danger--20);
}
:root .py-color-button-danger--30 {
  color: var(--py-color-button-danger--30);
}
:root .py-color-button-danger--40 {
  color: var(--py-color-button-danger--40);
}
:root .py-color-button-danger--50 {
  color: var(--py-color-button-danger--50);
}
:root .py-color-button-danger--60 {
  color: var(--py-color-button-danger--60);
}
:root .py-color-button-danger--70 {
  color: var(--py-color-button-danger--70);
}
:root .py-color-button-danger--80 {
  color: var(--py-color-button-danger--80);
}
:root .py-color-button-danger--90 {
  color: var(--py-color-button-danger--90);
}
:root .py-color-button-locked {
  color: var(--py-color-button-locked);
}
:root .py-color-button-locked--10 {
  color: var(--py-color-button-locked--10);
}
:root .py-color-button-locked--20 {
  color: var(--py-color-button-locked--20);
}
:root .py-color-button-locked--30 {
  color: var(--py-color-button-locked--30);
}
:root .py-color-button-locked--40 {
  color: var(--py-color-button-locked--40);
}
:root .py-color-button-locked--50 {
  color: var(--py-color-button-locked--50);
}
:root .py-color-button-locked--60 {
  color: var(--py-color-button-locked--60);
}
:root .py-color-button-locked--70 {
  color: var(--py-color-button-locked--70);
}
:root .py-color-button-locked--80 {
  color: var(--py-color-button-locked--80);
}
:root .py-color-button-locked--90 {
  color: var(--py-color-button-locked--90);
}
:root .py-color-line-hard {
  color: var(--py-color-line-hard);
}
:root .py-color-line-hard--10 {
  color: var(--py-color-line-hard--10);
}
:root .py-color-line-hard--20 {
  color: var(--py-color-line-hard--20);
}
:root .py-color-line-hard--30 {
  color: var(--py-color-line-hard--30);
}
:root .py-color-line-hard--40 {
  color: var(--py-color-line-hard--40);
}
:root .py-color-line-hard--50 {
  color: var(--py-color-line-hard--50);
}
:root .py-color-line-hard--60 {
  color: var(--py-color-line-hard--60);
}
:root .py-color-line-hard--70 {
  color: var(--py-color-line-hard--70);
}
:root .py-color-line-hard--80 {
  color: var(--py-color-line-hard--80);
}
:root .py-color-line-hard--90 {
  color: var(--py-color-line-hard--90);
}
:root .py-color-line-steady {
  color: var(--py-color-line-steady);
}
:root .py-color-line-steady--10 {
  color: var(--py-color-line-steady--10);
}
:root .py-color-line-steady--20 {
  color: var(--py-color-line-steady--20);
}
:root .py-color-line-steady--30 {
  color: var(--py-color-line-steady--30);
}
:root .py-color-line-steady--40 {
  color: var(--py-color-line-steady--40);
}
:root .py-color-line-steady--50 {
  color: var(--py-color-line-steady--50);
}
:root .py-color-line-steady--60 {
  color: var(--py-color-line-steady--60);
}
:root .py-color-line-steady--70 {
  color: var(--py-color-line-steady--70);
}
:root .py-color-line-steady--80 {
  color: var(--py-color-line-steady--80);
}
:root .py-color-line-steady--90 {
  color: var(--py-color-line-steady--90);
}
:root .py-color-line-soft {
  color: var(--py-color-line-soft);
}
:root .py-color-line-soft--10 {
  color: var(--py-color-line-soft--10);
}
:root .py-color-line-soft--20 {
  color: var(--py-color-line-soft--20);
}
:root .py-color-line-soft--30 {
  color: var(--py-color-line-soft--30);
}
:root .py-color-line-soft--40 {
  color: var(--py-color-line-soft--40);
}
:root .py-color-line-soft--50 {
  color: var(--py-color-line-soft--50);
}
:root .py-color-line-soft--60 {
  color: var(--py-color-line-soft--60);
}
:root .py-color-line-soft--70 {
  color: var(--py-color-line-soft--70);
}
:root .py-color-line-soft--80 {
  color: var(--py-color-line-soft--80);
}
:root .py-color-line-soft--90 {
  color: var(--py-color-line-soft--90);
}
:root .py-color-global-selected {
  color: var(--py-color-global-selected);
}
:root .py-color-global-selected--10 {
  color: var(--py-color-global-selected--10);
}
:root .py-color-global-selected--20 {
  color: var(--py-color-global-selected--20);
}
:root .py-color-global-selected--30 {
  color: var(--py-color-global-selected--30);
}
:root .py-color-global-selected--40 {
  color: var(--py-color-global-selected--40);
}
:root .py-color-global-selected--50 {
  color: var(--py-color-global-selected--50);
}
:root .py-color-global-selected--60 {
  color: var(--py-color-global-selected--60);
}
:root .py-color-global-selected--70 {
  color: var(--py-color-global-selected--70);
}
:root .py-color-global-selected--80 {
  color: var(--py-color-global-selected--80);
}
:root .py-color-global-selected--90 {
  color: var(--py-color-global-selected--90);
}
:root .py-color-global-hover {
  color: var(--py-color-global-hover);
}
:root .py-color-global-hover--10 {
  color: var(--py-color-global-hover--10);
}
:root .py-color-global-hover--20 {
  color: var(--py-color-global-hover--20);
}
:root .py-color-global-hover--30 {
  color: var(--py-color-global-hover--30);
}
:root .py-color-global-hover--40 {
  color: var(--py-color-global-hover--40);
}
:root .py-color-global-hover--50 {
  color: var(--py-color-global-hover--50);
}
:root .py-color-global-hover--60 {
  color: var(--py-color-global-hover--60);
}
:root .py-color-global-hover--70 {
  color: var(--py-color-global-hover--70);
}
:root .py-color-global-hover--80 {
  color: var(--py-color-global-hover--80);
}
:root .py-color-global-hover--90 {
  color: var(--py-color-global-hover--90);
}
:root .py-color-global-active {
  color: var(--py-color-global-active);
}
:root .py-color-global-active--10 {
  color: var(--py-color-global-active--10);
}
:root .py-color-global-active--20 {
  color: var(--py-color-global-active--20);
}
:root .py-color-global-active--30 {
  color: var(--py-color-global-active--30);
}
:root .py-color-global-active--40 {
  color: var(--py-color-global-active--40);
}
:root .py-color-global-active--50 {
  color: var(--py-color-global-active--50);
}
:root .py-color-global-active--60 {
  color: var(--py-color-global-active--60);
}
:root .py-color-global-active--70 {
  color: var(--py-color-global-active--70);
}
:root .py-color-global-active--80 {
  color: var(--py-color-global-active--80);
}
:root .py-color-global-active--90 {
  color: var(--py-color-global-active--90);
}
:root .py-color-global-active-hover {
  color: var(--py-color-global-active-hover);
}
:root .py-color-global-active-hover--10 {
  color: var(--py-color-global-active-hover--10);
}
:root .py-color-global-active-hover--20 {
  color: var(--py-color-global-active-hover--20);
}
:root .py-color-global-active-hover--30 {
  color: var(--py-color-global-active-hover--30);
}
:root .py-color-global-active-hover--40 {
  color: var(--py-color-global-active-hover--40);
}
:root .py-color-global-active-hover--50 {
  color: var(--py-color-global-active-hover--50);
}
:root .py-color-global-active-hover--60 {
  color: var(--py-color-global-active-hover--60);
}
:root .py-color-global-active-hover--70 {
  color: var(--py-color-global-active-hover--70);
}
:root .py-color-global-active-hover--80 {
  color: var(--py-color-global-active-hover--80);
}
:root .py-color-global-active-hover--90 {
  color: var(--py-color-global-active-hover--90);
}
:root .py-color-global-tooltip {
  color: var(--py-color-global-tooltip);
}
:root .py-color-global-tooltip--10 {
  color: var(--py-color-global-tooltip--10);
}
:root .py-color-global-tooltip--20 {
  color: var(--py-color-global-tooltip--20);
}
:root .py-color-global-tooltip--30 {
  color: var(--py-color-global-tooltip--30);
}
:root .py-color-global-tooltip--40 {
  color: var(--py-color-global-tooltip--40);
}
:root .py-color-global-tooltip--50 {
  color: var(--py-color-global-tooltip--50);
}
:root .py-color-global-tooltip--60 {
  color: var(--py-color-global-tooltip--60);
}
:root .py-color-global-tooltip--70 {
  color: var(--py-color-global-tooltip--70);
}
:root .py-color-global-tooltip--80 {
  color: var(--py-color-global-tooltip--80);
}
:root .py-color-global-tooltip--90 {
  color: var(--py-color-global-tooltip--90);
}
:root .py-color-banner-collection-blocks-1 {
  color: var(--py-color-banner-collection-blocks-1);
}
:root .py-color-banner-collection-blocks-1--10 {
  color: var(--py-color-banner-collection-blocks-1--10);
}
:root .py-color-banner-collection-blocks-1--20 {
  color: var(--py-color-banner-collection-blocks-1--20);
}
:root .py-color-banner-collection-blocks-1--30 {
  color: var(--py-color-banner-collection-blocks-1--30);
}
:root .py-color-banner-collection-blocks-1--40 {
  color: var(--py-color-banner-collection-blocks-1--40);
}
:root .py-color-banner-collection-blocks-1--50 {
  color: var(--py-color-banner-collection-blocks-1--50);
}
:root .py-color-banner-collection-blocks-1--60 {
  color: var(--py-color-banner-collection-blocks-1--60);
}
:root .py-color-banner-collection-blocks-1--70 {
  color: var(--py-color-banner-collection-blocks-1--70);
}
:root .py-color-banner-collection-blocks-1--80 {
  color: var(--py-color-banner-collection-blocks-1--80);
}
:root .py-color-banner-collection-blocks-1--90 {
  color: var(--py-color-banner-collection-blocks-1--90);
}
:root .py-color-banner-collection-blocks-2 {
  color: var(--py-color-banner-collection-blocks-2);
}
:root .py-color-banner-collection-blocks-2--10 {
  color: var(--py-color-banner-collection-blocks-2--10);
}
:root .py-color-banner-collection-blocks-2--20 {
  color: var(--py-color-banner-collection-blocks-2--20);
}
:root .py-color-banner-collection-blocks-2--30 {
  color: var(--py-color-banner-collection-blocks-2--30);
}
:root .py-color-banner-collection-blocks-2--40 {
  color: var(--py-color-banner-collection-blocks-2--40);
}
:root .py-color-banner-collection-blocks-2--50 {
  color: var(--py-color-banner-collection-blocks-2--50);
}
:root .py-color-banner-collection-blocks-2--60 {
  color: var(--py-color-banner-collection-blocks-2--60);
}
:root .py-color-banner-collection-blocks-2--70 {
  color: var(--py-color-banner-collection-blocks-2--70);
}
:root .py-color-banner-collection-blocks-2--80 {
  color: var(--py-color-banner-collection-blocks-2--80);
}
:root .py-color-banner-collection-blocks-2--90 {
  color: var(--py-color-banner-collection-blocks-2--90);
}
:root .py-color-banner-collection-blocks-3 {
  color: var(--py-color-banner-collection-blocks-3);
}
:root .py-color-banner-collection-blocks-3--10 {
  color: var(--py-color-banner-collection-blocks-3--10);
}
:root .py-color-banner-collection-blocks-3--20 {
  color: var(--py-color-banner-collection-blocks-3--20);
}
:root .py-color-banner-collection-blocks-3--30 {
  color: var(--py-color-banner-collection-blocks-3--30);
}
:root .py-color-banner-collection-blocks-3--40 {
  color: var(--py-color-banner-collection-blocks-3--40);
}
:root .py-color-banner-collection-blocks-3--50 {
  color: var(--py-color-banner-collection-blocks-3--50);
}
:root .py-color-banner-collection-blocks-3--60 {
  color: var(--py-color-banner-collection-blocks-3--60);
}
:root .py-color-banner-collection-blocks-3--70 {
  color: var(--py-color-banner-collection-blocks-3--70);
}
:root .py-color-banner-collection-blocks-3--80 {
  color: var(--py-color-banner-collection-blocks-3--80);
}
:root .py-color-banner-collection-blocks-3--90 {
  color: var(--py-color-banner-collection-blocks-3--90);
}
:root .py-color-primary-dark {
  color: var(--py-color-primary-dark);
}
:root .py-color-primary-dark--10 {
  color: var(--py-color-primary-dark--10);
}
:root .py-color-primary-dark--20 {
  color: var(--py-color-primary-dark--20);
}
:root .py-color-primary-dark--30 {
  color: var(--py-color-primary-dark--30);
}
:root .py-color-primary-dark--40 {
  color: var(--py-color-primary-dark--40);
}
:root .py-color-primary-dark--50 {
  color: var(--py-color-primary-dark--50);
}
:root .py-color-primary-dark--60 {
  color: var(--py-color-primary-dark--60);
}
:root .py-color-primary-dark--70 {
  color: var(--py-color-primary-dark--70);
}
:root .py-color-primary-dark--80 {
  color: var(--py-color-primary-dark--80);
}
:root .py-color-primary-dark--90 {
  color: var(--py-color-primary-dark--90);
}
:root .py-color-primary {
  color: var(--py-color-primary);
}
:root .py-color-primary--10 {
  color: var(--py-color-primary--10);
}
:root .py-color-primary--20 {
  color: var(--py-color-primary--20);
}
:root .py-color-primary--30 {
  color: var(--py-color-primary--30);
}
:root .py-color-primary--40 {
  color: var(--py-color-primary--40);
}
:root .py-color-primary--50 {
  color: var(--py-color-primary--50);
}
:root .py-color-primary--60 {
  color: var(--py-color-primary--60);
}
:root .py-color-primary--70 {
  color: var(--py-color-primary--70);
}
:root .py-color-primary--80 {
  color: var(--py-color-primary--80);
}
:root .py-color-primary--90 {
  color: var(--py-color-primary--90);
}
:root .py-color-primary-light {
  color: var(--py-color-primary-light);
}
:root .py-color-primary-light--10 {
  color: var(--py-color-primary-light--10);
}
:root .py-color-primary-light--20 {
  color: var(--py-color-primary-light--20);
}
:root .py-color-primary-light--30 {
  color: var(--py-color-primary-light--30);
}
:root .py-color-primary-light--40 {
  color: var(--py-color-primary-light--40);
}
:root .py-color-primary-light--50 {
  color: var(--py-color-primary-light--50);
}
:root .py-color-primary-light--60 {
  color: var(--py-color-primary-light--60);
}
:root .py-color-primary-light--70 {
  color: var(--py-color-primary-light--70);
}
:root .py-color-primary-light--80 {
  color: var(--py-color-primary-light--80);
}
:root .py-color-primary-light--90 {
  color: var(--py-color-primary-light--90);
}
:root .py-color-secondary-darker {
  color: var(--py-color-secondary-darker);
}
:root .py-color-secondary-darker--10 {
  color: var(--py-color-secondary-darker--10);
}
:root .py-color-secondary-darker--20 {
  color: var(--py-color-secondary-darker--20);
}
:root .py-color-secondary-darker--30 {
  color: var(--py-color-secondary-darker--30);
}
:root .py-color-secondary-darker--40 {
  color: var(--py-color-secondary-darker--40);
}
:root .py-color-secondary-darker--50 {
  color: var(--py-color-secondary-darker--50);
}
:root .py-color-secondary-darker--60 {
  color: var(--py-color-secondary-darker--60);
}
:root .py-color-secondary-darker--70 {
  color: var(--py-color-secondary-darker--70);
}
:root .py-color-secondary-darker--80 {
  color: var(--py-color-secondary-darker--80);
}
:root .py-color-secondary-darker--90 {
  color: var(--py-color-secondary-darker--90);
}
:root .py-color-secondary-dark {
  color: var(--py-color-secondary-dark);
}
:root .py-color-secondary-dark--10 {
  color: var(--py-color-secondary-dark--10);
}
:root .py-color-secondary-dark--20 {
  color: var(--py-color-secondary-dark--20);
}
:root .py-color-secondary-dark--30 {
  color: var(--py-color-secondary-dark--30);
}
:root .py-color-secondary-dark--40 {
  color: var(--py-color-secondary-dark--40);
}
:root .py-color-secondary-dark--50 {
  color: var(--py-color-secondary-dark--50);
}
:root .py-color-secondary-dark--60 {
  color: var(--py-color-secondary-dark--60);
}
:root .py-color-secondary-dark--70 {
  color: var(--py-color-secondary-dark--70);
}
:root .py-color-secondary-dark--80 {
  color: var(--py-color-secondary-dark--80);
}
:root .py-color-secondary-dark--90 {
  color: var(--py-color-secondary-dark--90);
}
:root .py-color-secondary {
  color: var(--py-color-secondary);
}
:root .py-color-secondary--10 {
  color: var(--py-color-secondary--10);
}
:root .py-color-secondary--20 {
  color: var(--py-color-secondary--20);
}
:root .py-color-secondary--30 {
  color: var(--py-color-secondary--30);
}
:root .py-color-secondary--40 {
  color: var(--py-color-secondary--40);
}
:root .py-color-secondary--50 {
  color: var(--py-color-secondary--50);
}
:root .py-color-secondary--60 {
  color: var(--py-color-secondary--60);
}
:root .py-color-secondary--70 {
  color: var(--py-color-secondary--70);
}
:root .py-color-secondary--80 {
  color: var(--py-color-secondary--80);
}
:root .py-color-secondary--90 {
  color: var(--py-color-secondary--90);
}
:root .py-color-secondary-light {
  color: var(--py-color-secondary-light);
}
:root .py-color-secondary-light--10 {
  color: var(--py-color-secondary-light--10);
}
:root .py-color-secondary-light--20 {
  color: var(--py-color-secondary-light--20);
}
:root .py-color-secondary-light--30 {
  color: var(--py-color-secondary-light--30);
}
:root .py-color-secondary-light--40 {
  color: var(--py-color-secondary-light--40);
}
:root .py-color-secondary-light--50 {
  color: var(--py-color-secondary-light--50);
}
:root .py-color-secondary-light--60 {
  color: var(--py-color-secondary-light--60);
}
:root .py-color-secondary-light--70 {
  color: var(--py-color-secondary-light--70);
}
:root .py-color-secondary-light--80 {
  color: var(--py-color-secondary-light--80);
}
:root .py-color-secondary-light--90 {
  color: var(--py-color-secondary-light--90);
}
:root .py-color-grey-light {
  color: var(--py-color-grey-light);
}
:root .py-color-grey-light--10 {
  color: var(--py-color-grey-light--10);
}
:root .py-color-grey-light--20 {
  color: var(--py-color-grey-light--20);
}
:root .py-color-grey-light--30 {
  color: var(--py-color-grey-light--30);
}
:root .py-color-grey-light--40 {
  color: var(--py-color-grey-light--40);
}
:root .py-color-grey-light--50 {
  color: var(--py-color-grey-light--50);
}
:root .py-color-grey-light--60 {
  color: var(--py-color-grey-light--60);
}
:root .py-color-grey-light--70 {
  color: var(--py-color-grey-light--70);
}
:root .py-color-grey-light--80 {
  color: var(--py-color-grey-light--80);
}
:root .py-color-grey-light--90 {
  color: var(--py-color-grey-light--90);
}
:root .py-color-grey-lighter {
  color: var(--py-color-grey-lighter);
}
:root .py-color-grey-lighter--10 {
  color: var(--py-color-grey-lighter--10);
}
:root .py-color-grey-lighter--20 {
  color: var(--py-color-grey-lighter--20);
}
:root .py-color-grey-lighter--30 {
  color: var(--py-color-grey-lighter--30);
}
:root .py-color-grey-lighter--40 {
  color: var(--py-color-grey-lighter--40);
}
:root .py-color-grey-lighter--50 {
  color: var(--py-color-grey-lighter--50);
}
:root .py-color-grey-lighter--60 {
  color: var(--py-color-grey-lighter--60);
}
:root .py-color-grey-lighter--70 {
  color: var(--py-color-grey-lighter--70);
}
:root .py-color-grey-lighter--80 {
  color: var(--py-color-grey-lighter--80);
}
:root .py-color-grey-lighter--90 {
  color: var(--py-color-grey-lighter--90);
}
:root .py-color-success {
  color: var(--py-color-success);
}
:root .py-color-success--10 {
  color: var(--py-color-success--10);
}
:root .py-color-success--20 {
  color: var(--py-color-success--20);
}
:root .py-color-success--30 {
  color: var(--py-color-success--30);
}
:root .py-color-success--40 {
  color: var(--py-color-success--40);
}
:root .py-color-success--50 {
  color: var(--py-color-success--50);
}
:root .py-color-success--60 {
  color: var(--py-color-success--60);
}
:root .py-color-success--70 {
  color: var(--py-color-success--70);
}
:root .py-color-success--80 {
  color: var(--py-color-success--80);
}
:root .py-color-success--90 {
  color: var(--py-color-success--90);
}
:root .py-color-warning {
  color: var(--py-color-warning);
}
:root .py-color-warning--10 {
  color: var(--py-color-warning--10);
}
:root .py-color-warning--20 {
  color: var(--py-color-warning--20);
}
:root .py-color-warning--30 {
  color: var(--py-color-warning--30);
}
:root .py-color-warning--40 {
  color: var(--py-color-warning--40);
}
:root .py-color-warning--50 {
  color: var(--py-color-warning--50);
}
:root .py-color-warning--60 {
  color: var(--py-color-warning--60);
}
:root .py-color-warning--70 {
  color: var(--py-color-warning--70);
}
:root .py-color-warning--80 {
  color: var(--py-color-warning--80);
}
:root .py-color-warning--90 {
  color: var(--py-color-warning--90);
}
:root .py-color-info {
  color: var(--py-color-info);
}
:root .py-color-info--10 {
  color: var(--py-color-info--10);
}
:root .py-color-info--20 {
  color: var(--py-color-info--20);
}
:root .py-color-info--30 {
  color: var(--py-color-info--30);
}
:root .py-color-info--40 {
  color: var(--py-color-info--40);
}
:root .py-color-info--50 {
  color: var(--py-color-info--50);
}
:root .py-color-info--60 {
  color: var(--py-color-info--60);
}
:root .py-color-info--70 {
  color: var(--py-color-info--70);
}
:root .py-color-info--80 {
  color: var(--py-color-info--80);
}
:root .py-color-info--90 {
  color: var(--py-color-info--90);
}

.btn-primary, py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button button {
  color: var(--py-color-white);
  background-color: var(--py-color-button-primary);
  border-color: var(--py-color-button-primary);
  box-shadow: 0 2px 4px var(--py-color-button-primary--20);
}
.btn-primary:hover, py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button button:hover {
  background-color: var(--py-color-button-primary);
  border-color: var(--py-color-primary-700);
  box-shadow: 0 4px 8px var(--py-color-button-primary--40);
  text-decoration: underline;
}
.btn-primary:focus, py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button button:focus {
  background-color: var(--py-color-button-primary) !important;
  border-color: var(--py-color-primary-700) !important;
}
.btn-primary:active, py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button button:active {
  background-color: var(--py-color-button-primary) !important;
  border-color: var(--py-color-primary-700) !important;
  box-shadow: 0 4px 8px var(--py-color-button-primary--40);
}
.btn-primary:disabled, py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button button:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-secondary {
  color: var(--py-color-white);
  background-color: var(--py-color-button-secondary);
  border-color: var(--py-color-button-secondary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover {
  background-color: var(--py-color-button-secondary);
  border-color: var(--py-color-secondary-700);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  text-decoration: underline;
}
.btn-secondary:focus {
  background-color: var(--py-color-button-secondary) !important;
  border-color: var(--py-color-secondary-700) !important;
}
.btn-secondary:active {
  background-color: var(--py-color-button-secondary) !important;
  border-color: var(--py-color-secondary-700) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.btn-secondary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-danger {
  color: var(--py-color-white);
  background-color: var(--py-color-button-danger);
  border-color: var(--py-color-button-danger);
  box-shadow: 0 2px 4px var(--py-color-button-danger--20);
}
.btn-danger:hover {
  background-color: var(--py-color-button-danger);
  border-color: var(--py-color-danger-700);
  box-shadow: 0 4px 8px var(--py-color-button-danger--40);
  text-decoration: underline;
}
.btn-danger:focus {
  background-color: var(--py-color-button-danger) !important;
  border-color: var(--py-color-danger-700) !important;
}
.btn-danger:active {
  background-color: var(--py-color-button-danger) !important;
  border-color: var(--py-color-danger-700) !important;
  box-shadow: 0 4px 8px var(--py-color-button-danger--40);
}
.btn-danger:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-primary {
  color: var(--py-color-button-primary);
  border-color: var(--py-color-line-steady);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-primary:hover {
  text-decoration: underline;
}
.btn-plain-primary:hover, .btn-plain-primary:active, .btn-plain-primary:focus {
  color: var(--py-color-button-primary);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-primary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-primary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-plain-secondary {
  color: var(--py-color-black);
  border-color: var(--py-color-line-steady);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-secondary:hover {
  text-decoration: underline;
}
.btn-plain-secondary:hover, .btn-plain-secondary:active, .btn-plain-secondary:focus {
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-secondary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-secondary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-plain-danger {
  color: var(--py-color-button-danger);
  border-color: var(--py-color-line-steady);
  background-color: var(--py-color-white);
  box-shadow: none !important;
}
.btn-plain-danger:hover {
  text-decoration: underline;
}
.btn-plain-danger:hover, .btn-plain-danger:active, .btn-plain-danger:focus {
  color: var(--py-color-button-danger);
  background-color: var(--py-color-white);
  border-color: var(--py-color-global-active);
}
.btn-plain-danger:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-plain-danger:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-ternary {
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  border-color: var(--py-color-grey-light);
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
  box-shadow: none !important;
}
.btn-ternary:hover {
  background-color: var(--py-color-white);
  border-color: var(--py-color-primary);
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
  text-decoration: underline;
}
.btn-ternary:focus {
  background-color: var(--py-color-white) !important;
  border-color: var(--py-color-primary) !important;
}
.btn-ternary:active {
  background-color: var(--py-color-grey-lighter) !important;
  border-color: var(--py-color-primary) !important;
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
}
.btn-ternary:disabled {
  background-color: var(--py-color-button-locked) !important;
  border-color: var(--py-color-button-locked) !important;
  color: var(--py-color-grey-500);
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled) {
  border-width: 1px;
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled):not(.btn-icon) {
  padding: calc(0.7em + 1px) calc(1.8em + 1px);
}
.btn-ternary:not(:active):not(:focus):not(:hover):not(:disabled).btn-icon {
  padding: calc(0.3em + 1px);
}
.btn-ternary:disabled {
  border: 2px solid var(--py-color-grey-light);
}
.btn:not(.btn-link):focus {
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
}

cx-page-layout.footer {
  background-color: var(--py-color-primary-900);
}

py-catalog-article-price .catalog-article-price {
  font-family: var(--py-font-secondary-bold) !important;
}
py-catalog-article-price .catalog-article-list-item-quantity {
  font-family: var(--py-font-secondary) !important;
}

py-article-info {
  font-family: var(--py-font-secondary) !important;
}

py-registration h5 {
  font-family: var(--py-font-secondary) !important;
}

li[py-order-entry-list-item] py-article-price span.price {
  font-family: var(--py-font-secondary-bold) !important;
}

py-header-navigation-item {
  border-radius: 4px !important;
}
py-header-navigation-item:hover {
  background-color: var(--py-color-grey-100) !important;
  text-decoration: underline;
}
py-header-navigation-item.active {
  background-color: var(--py-color-grey-200) !important;
}

py-mail-form .mail-form.mailform-newsletter {
  max-width: none;
  border-top: none !important;
  background-image: url("../../assets-papyrus/images/lines-pattern.jpg");
  background-size: contain;
}
py-mail-form .mail-form.mailform-newsletter > form {
  color: var(--py-color-grey-200) !important;
  background-color: unset !important;
  padding: 0 !important;
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
py-mail-form .mail-form.mailform-newsletter > form .lead-signup-label {
  font-family: var(--py-font-secondary-bold);
}
py-mail-form .mail-form.mailform-newsletter > form .sub-signup-label {
  padding-bottom: 16px !important;
}
py-mail-form .mail-form.mailform-newsletter > form .email-container {
  display: flex;
  position: unset !important;
  width: unset !important;
  left: unset !important;
  top: unset !important;
}
py-mail-form .mail-form.mailform-newsletter > form .email-container py-reactive-form-input-field {
  margin-right: 0.5rem;
}
@media (max-width: 991.98px) {
  py-mail-form .mail-form.mailform-newsletter > form .email-container {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  py-mail-form .mail-form.mailform-newsletter > form .email-container py-action-button {
    max-width: none !important;
    margin-top: 0.5rem;
  }
}
py-mail-form .mail-form.mailform-newsletter > form .bottom-signup-label {
  position: unset !important;
  width: unset !important;
  left: unset !important;
  top: unset !important;
  margin-top: 1rem;
}
py-mail-form .mail-form.mailform-newsletter > form a {
  color: var(--py-color-info-400) !important;
}

py-footer-navigation {
  background-color: var(--py-color-primary-900) !important;
}
py-footer-navigation .container {
  padding-bottom: 0 !important;
}
py-footer-navigation .middle-right-col .link-item-wrapper,
py-footer-navigation .right-col .link-item-wrapper {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
cx-navigation .navigation-header {
  font-size: 18px !important;
  color: var(--py-color-grey-200) !important;
  margin-bottom: 1rem !important;
}
cx-navigation .navigation-body .has-content,
cx-navigation .navigation-body-horizontal:not(.certificate-link) .has-content {
  padding-bottom: 16px;
}
cx-navigation .navigation-body-horizontal.certificate-link .link-item-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  cx-navigation .navigation-body-horizontal .has-content {
    padding-bottom: 0;
  }
}
cx-navigation .navigation-body .no-link,
cx-navigation .navigation-body-horizontal .no-link {
  color: var(--py-color-grey-200) !important;
}
cx-navigation .navigation-body a.open-consent-link,
cx-navigation .navigation-body cx-generic-link a,
cx-navigation .navigation-body-horizontal a.open-consent-link,
cx-navigation .navigation-body-horizontal cx-generic-link a {
  display: flex;
  color: var(--py-color-info-400) !important;
}
cx-navigation .navigation-body a.open-consent-link:focus, cx-navigation .navigation-body a.open-consent-link:visited, cx-navigation .navigation-body a.open-consent-link:active,
cx-navigation .navigation-body cx-generic-link a:focus,
cx-navigation .navigation-body cx-generic-link a:visited,
cx-navigation .navigation-body cx-generic-link a:active,
cx-navigation .navigation-body-horizontal a.open-consent-link:focus,
cx-navigation .navigation-body-horizontal a.open-consent-link:visited,
cx-navigation .navigation-body-horizontal a.open-consent-link:active,
cx-navigation .navigation-body-horizontal cx-generic-link a:focus,
cx-navigation .navigation-body-horizontal cx-generic-link a:visited,
cx-navigation .navigation-body-horizontal cx-generic-link a:active {
  color: var(--py-color-info-400) !important;
}
cx-navigation .navigation-body a.open-consent-link:hover,
cx-navigation .navigation-body cx-generic-link a:hover,
cx-navigation .navigation-body-horizontal a.open-consent-link:hover,
cx-navigation .navigation-body-horizontal cx-generic-link a:hover {
  color: var(--py-color-info-200) !important;
}
cx-navigation .navigation-body a.open-consent-link:hover i,
cx-navigation .navigation-body cx-generic-link a:hover i,
cx-navigation .navigation-body-horizontal a.open-consent-link:hover i,
cx-navigation .navigation-body-horizontal cx-generic-link a:hover i {
  color: var(--py-color-info-200) !important;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
cx-page-slot.FooterLogo,
[cx-page-slot].FooterLogo {
  flex-direction: row !important;
}
cx-page-slot.FooterLogo::before,
[cx-page-slot].FooterLogo::before {
  content: none !important;
}
cx-page-slot.FooterLogo a,
[cx-page-slot].FooterLogo a {
  color: var(--py-color-primary-400) !important;
}
cx-page-slot.FooterLogo a i,
[cx-page-slot].FooterLogo a i {
  color: var(--py-color-white) !important;
}
cx-page-slot.FooterLogo py-banner,
[cx-page-slot].FooterLogo py-banner {
  flex: none !important;
}
cx-page-slot.FooterLogo py-banner.site-logo,
[cx-page-slot].FooterLogo py-banner.site-logo {
  margin-top: 1.25rem;
  margin-left: 0;
}
@media (min-width: 768px) {
  cx-page-slot.FooterLogo py-banner.site-logo,
  [cx-page-slot].FooterLogo py-banner.site-logo {
    margin-top: 0;
    margin-left: 1.25rem;
  }
}
cx-page-slot.FooterLogo py-banner.site-logo [pyMedia],
[cx-page-slot].FooterLogo py-banner.site-logo [pyMedia] {
  max-width: 140px !important;
}
cx-page-slot.FooterLogo py-paragraph,
[cx-page-slot].FooterLogo py-paragraph {
  flex: none !important;
}
@media (max-width: 991.98px) {
  cx-page-slot.FooterLogo py-paragraph,
  [cx-page-slot].FooterLogo py-paragraph {
    display: initial !important;
  }
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder {
  font-size: 16px;
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder div,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder div {
  color: var(--py-color-grey-200) !important;
}
cx-page-slot.FooterLogo py-paragraph .paragraph-holder div a:hover,
[cx-page-slot].FooterLogo py-paragraph .paragraph-holder div a:hover {
  color: var(--py-color-info-200) !important;
}
@media (max-width: 991.98px) {
  cx-page-slot.FooterLogo,
  [cx-page-slot].FooterLogo {
    height: auto !important;
    flex-direction: column !important;
    padding-bottom: 50px;
  }
  cx-page-slot.FooterLogo > :first-child,
  cx-page-slot.FooterLogo > :last-child,
  [cx-page-slot].FooterLogo > :first-child,
  [cx-page-slot].FooterLogo > :last-child {
    margin-left: initial;
    margin-right: initial;
  }
}
@media print {
  cx-page-slot.FooterLogo,
  [cx-page-slot].FooterLogo {
    page-break-before: always !important;
  }
  cx-page-slot.FooterLogo py-link-collection,
  cx-page-slot.FooterLogo py-country-selector,
  [cx-page-slot].FooterLogo py-link-collection,
  [cx-page-slot].FooterLogo py-country-selector {
    display: none !important;
  }
}

py-delivery-timeline .tracking-number-wrapper .tracking-number-item {
  border-radius: 4px !important;
}

py-cart-message-modal py-article-price span.price {
  font-family: var(--py-font-secondary-bold) !important;
}

py-checkbox .checkmark {
  border-radius: 3px !important;
}

py-notification.warning {
  border: 1px solid var(--py-color-warning-600) !important;
  background-color: var(--py-color-warning-100) !important;
}
py-notification.warning i {
  color: var(--py-color-warning-600) !important;
}

py-search .search-results i[type=contracted-article] {
  font-size: 22px;
}

:root {
  --py-font-primary: primary, primary-fallback-0, primary-fallback-1, sans-serif;
  --py-font-primary-light: primary-light, primary-light-fallback-0, primary-light-fallback-1, sans-serif;
  --py-font-secondary: secondary, secondary-fallback-0, secondary-fallback-1, sans-serif;
  --py-font-secondary-bold: secondary-bold, secondary-bold-fallback-0, secondary-bold-fallback-1, sans-serif;
}
:root .py-font-primary {
  font-family: var(--py-font-primary);
}
:root .py-font-primary-light {
  font-family: var(--py-font-primary-light);
}
:root .py-font-secondary {
  font-family: var(--py-font-secondary);
}
:root .py-font-secondary-bold {
  font-family: var(--py-font-secondary-bold);
}

cx-page-layout.LogoutPageTemplate,
cx-page-layout.LoginPageTemplate,
cx-page-layout.PasswordResetPageTemplate {
  background-image: url("../assets-papyrus/images/blue-wallpaper.png");
  background-image: -webkit-image-set(url("../assets-papyrus/images/blue-wallpaper.webp") 1x);
}

cx-page-layout.RegistrationPageTemplate,
cx-page-layout.CheckoutPageTemplate,
cx-page-layout.OrderConfirmationPageTemplate,
cx-page-layout.PaymentProcessingPageTemplate {
  background-image: url("../assets-papyrus/images/dot-pattern-1.jpg");
  background-image: -webkit-image-set(url("../assets-papyrus/images/dot-pattern-1.webp") 1x);
  background-repeat: repeat;
  background-size: auto;
}

py-storefront.BrandLandingPageTemplate main {
  background-image: url("../assets-papyrus/images/brand-landing-page-background.jpg");
  background-image: -webkit-image-set(url("../assets-papyrus/images/brand-landing-page-background.webp") 1x);
  background-position: center;
  background-size: cover;
}

py-storefront.ErrorPageTemplate main {
  background-image: url("../assets-papyrus/images/error-not-found-background.jpg");
  background-image: -webkit-image-set(url("../assets-papyrus/images/error-not-found-background.webp") 1x);
  background-position: center;
  background-size: cover;
  z-index: -1;
}