$py-brand-colors: (
  // Primary
  'primary-900': #030810,
  'primary-800': #07101f,
  'primary-700': #11284e,
  'primary-600': #1c407c,
  'primary-500': #23509b,
  'primary-400': #4f73af,
  'primary-300': #90a7cd,
  'primary-200': #d3dceb,
  'primary-100': #e8edf5,

  // Secondary
  'secondary-900': #030408,
  'secondary-800': #060810,
  'secondary-700': #0f1527,
  'secondary-600': #18223f,
  'secondary-500': #1e2b4f,
  'secondary-400': #4b5572,
  'secondary-300': #8e94a6,
  'secondary-200': #d2d4dc,
  'secondary-100': #e8e9ed,

  // Button colors
  'button-primary': #23509b,
  'button-secondary': #1e2b4f,
  'button-danger': #f43f3f,
  'button-locked': #e5e5e6,

  // Line
  'line-hard': #828385,
  'line-steady': #cbcbcd,
  'line-soft': #e5e5e6,

  // Global
  'global-selected': #e8edf5,
  'global-hover': #d3dceb,
  'global-active': #23509b,
  'global-active-hover': #11284e,
  'global-tooltip': #646568,

  // Banner collection blocks
  'banner-collection-blocks-1': #a2c3cd,
  'banner-collection-blocks-2': #dae6ea,
  'banner-collection-blocks-3': #4d8799,

  // Deprecated colors from legacy designs - DO NOT USE IN NEWLY DEVELOPED CODE
  // TODO ESVCX-5948 - remove legacy colors
  // Deprecated colors START
  'primary-dark': #23509b,
  'primary': #4f73af,
  'primary-light': #90a7cd,
  'secondary-darker': #4f73af,
  'secondary-dark': #90a7cd,
  'secondary': #23509b,
  'secondary-light': #d3dceb,
  'grey-light': #e5e5e6,
  'grey-lighter': #f2f2f2,
  'success': #92c139,
  'warning': #ffc156,
  'info': #4f73af,
  // Deprecated colors END
) !default;

:root {
  @include generate-py-variables($py-brand-colors);
}
